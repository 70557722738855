@font-face {
	font-family: 'Casta-SemiBoldCondensed';
	src: url('../../assets/fonts/Casta-SemiBoldCondensed.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: 'Casta-SemiBold';
	src: url('../../assets/fonts/Casta-SemiBold.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

$FONT: 'Poppins';
$FONT_CTA: 'Casta-SemiBold';
$FONT_TITLE: 'Casta-SemiBoldCondensed'; //'Rakkas';
