.SubpageView {
	position: absolute;
	font-family: $FONT;
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	text-align: center;
	width: 100%;
	background-color: #000000;
	color: #ffffff;

	.closeButton {
		position: absolute;
		right: 40px;
		top: 40px;
		z-index: 2;
		height: 44px;
		cursor: pointer;
		pointer-events: auto;

		svg {
			margin-right: 9px;
		}

		.label {
			vertical-align: middle;
			color: white;
			font-family: $FONT;
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 20px;
			display: inline-block;
			height: 50px;
			user-select: none;
		}

		@media (max-width: 768px) {
			.label {
				display: none;
			}
		}
	}

	.page {
		//	display: none;

		padding: 100px;
		height: auto;
		max-width: 1000px;
		margin: auto;
		font-weight: bold;
		pointer-events: auto;

		@media (max-width: 768px) {
			padding: 200px 20px;
		}

		code {
			font-family: monospace;
			background: #555555;
			word-wrap: break-word;
			box-decoration-break: clone;
			padding: 0.1rem 0.3rem 0.2rem;
			//border-radius: .2rem;
		}

		h2 {
			font-weight: normal;
		}

		.body {
			margin-top: 50px;
			font-size: 16px;
			font-weight: lighter;
			line-height: 1.5;
			text-align: justify;

			video {
				width: 100%;
			}
		}
	}
}
