html,
body {
	font-size: 22px;
	font-family: $FONT;

	vertical-align: baseline;
	font-weight: normal;

	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	-webkit-tap-highlight-color: transparent;

	//	text-rendering: auto;
	//	text-rendering: optimizeSpeed;
	//  text-rendering: optimizeLegibility;
	//	text-rendering: geometricPrecision;
	//	text-rendering: auto;
}

text {
	font-family: $FONT;
}

body {
	overflow-x: hidden;
	overflow-y: scroll;

	overscroll-behavior: none;

	background-color: $COLOR_BLACK;

	color: $COLOR_WHITE;

	font-family: $FONT_TITLE;
}

#mainViewContainer {
	position: absolute;
	width: 100vw;
	height: 100vh;
	overflow-x: hidden;
	overflow-y: scroll;
}

#centerCTAOuter {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	pointer-events: none;

	#centerCTA {
		opacity: 0;

		.innerContent {
			text-align: center;
			display: flex;

			@media only screen and (max-width: 1000px) {
				display: block;
			}

			.subheader {
				font-size: 12px;
				font-weight: bold;
				margin-top: 0px;
				color: #ffffff;
				opacity: 0.5;
				max-width: 400px;
				margin-bottom: 0px;
				height: 60px;
				width: 600px;

				@media only screen and (max-width: 1000px) {
					min-height: auto;
					display: none;
				}
			}

			.giveAHand {
				margin-right: 150px;
				@media only screen and (max-width: 1000px) {
					margin-right: 0px;
				}

				.subHeadline {
					//	opacity: 0;
					font-size: 12px;
					font-weight: bold;
				}

				.headline {
					//	opacity: 0;
					font-size: 48px;
					font-family: $FONT_TITLE;
					margin-top: 20px;
					margin-bottom: 10px;

					@media only screen and (max-width: 1000px) {
						//	font-size: 26px;
						margin-top: 20px;
						margin-bottom: 10px;
					}
				}

				.button {
					//	opacity: 0;
					background-color: #d65e3d;
					border-radius: 40px;
					padding: 12px 12px;
					width: 220px;
					margin: auto;
					font-size: 12px;
					font-weight: bold;
					cursor: pointer;
					pointer-events: all;
				}
			}

			.browseDataset {
				margin-top: 0px;

				@media only screen and (max-width: 1000px) {
					margin-top: 30px;
				}

				.subheader {
					padding-top: 5px;
				}

				.subHeadline {
					opacity: 0;
					font-size: 12px;
					font-weight: bold;
				}

				.headline {
					//	opacity: 0;
					font-size: 48px;
					font-family: $FONT_TITLE;
					margin-top: 0px;
					margin-bottom: 10px;

					@media only screen and (max-width: 1000px) {
						//	font-size: 26px;
					}
				}

				.buttonContainer {
					width: 220px;
					margin: auto;
					border: 1px solid white;
					border-radius: 40px;
					height: 44px;

					.button {
						//	opacity: 0;

						padding: 12px 12px;

						font-size: 12px;
						font-weight: bold;
						cursor: pointer;
						pointer-events: all;
					}
				}

				.buttonInvertedOuter {
					position: relative;
					top: -43px;
					left: 90px;
					overflow: clip;
					width: 0px;

					.buttonInverted {
						border: 1px solid white;
						border-radius: 40px;
						padding: 12px 12px;
						width: 220px;
						//margin-left: 100px;
						font-size: 12px;
						font-weight: bold;
						cursor: pointer;
						color: black;
						background-color: white;

						@media only screen and (max-width: 1000px) {
							margin-left: 0px;
							//width: 180px;
						}
					}
					@media only screen and (max-width: 1000px) {
						left: 0px;
						//width: 180px;
					}
				}
			}
		}
	}
}

.volumeButtons {
	position: fixed;
	bottom: 60px;
	left: 40px;
	filter: drop-shadow(2px 2px 2px rgba(0.5, 0, 0, 0));
	cursor: pointer;
	pointer-events: all;
	opacity: 0;

	.volumeOff {
		position: absolute;
		top: 0px;
		opacity: 0;
	}

	.volumeOn {
		position: absolute;
		top: 0px;
	}
}

#HandData {
	position: fixed;
	background-color: #111111;
	width: 400px;
	right: 0px;
	height: 100vh;
	transform: translateX(50vw);

	@media (max-width: 768px) {
		display: none;
	}

	.closeButton {
		position: absolute;
		left: 40px;
		top: 40px;
		z-index: 2;
		height: 44px;
		cursor: pointer;

		svg {
			margin-right: 9px;
		}

		.label {
			vertical-align: middle;
			color: white;
			font-family: $FONT;
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 20px;
			display: inline-block;
			height: 50px;
			user-select: none;
		}

		@media (max-width: 768px) {
			.label {
				display: none;
			}
		}
	}

	.handInformation {
		position: absolute;
		bottom: 30vh;
		left: 60px;

		.date {
			font-size: 10px;
			margin-bottom: 20px;
		}

		.id {
			font-size: 64px;
			font-family: $FONT_TITLE;

			.uniqueNumber {
				font-family: $FONT_TITLE;
			}
		}

		.arrows {
			margin-top: 20px;
			display: flex;

			.leftArrow {
				margin-right: 18px;
				cursor: pointer;
			}

			.rightArrow {
				cursor: pointer;
			}
		}

		.statusArea {
			margin-top: 20px;
			font-size: 10px;

			.statusHeadline {
				margin-bottom: 10px;
			}

			.statusItem {
				margin-top: 5px;
				display: flex;
				opacity: 0.5;

				.circle {
					width: 10px;
					height: 10px;
					border-radius: 50%;
					border: 1px solid white;
					margin-right: 10px;
					margin-top: 3px;
				}

				.statusName {
					//font-style: italic;
				}
			}
		}
	}
}

#outerElementsInfo {
	#aProjectBy {
		text-align: right;
		font-size: 12px;
		position: fixed;
		right: 40px;
		top: 40px;
		opacity: 0.5;

		a,
		a:link,
		a:visited,
		a:active {
			color: inherit;
			text-decoration: underline;
		}
	}
}

#outerElements {
	font-family: 'poppins';
	pointer-events: none;
	cursor: default;

	#logo {
		transform: translateX(-200px);
		position: fixed;
		top: 40px;
		left: 40px;
		font-weight: bold;
		font-family: 'poppins';
		font-size: 14px;
		line-height: 15px;
		pointer-events: none;
		cursor: default;
	}

	#totalHandsGathered {
		position: fixed;
		letter-spacing: 1px;
		font-size: 10px;
		bottom: 50px;
		left: 0px;
		width: 100%;
		text-align: center;
		pointer-events: none;
		transform: translateY(100px);
	}

	#subMenu {
		transform: translateX(-200px);
		position: fixed;
		bottom: 40px;
		left: 40px;
		font-size: 10px;
		letter-spacing: 1px;
		border-top: 1px solid rgba(255, 255, 255, 0.2);

		.menuItem {
			cursor: pointer;
			margin-top: 6px;
			padding-bottom: 6px;
			min-width: 150px;
			border-bottom: 1px solid rgba(255, 255, 255, 0.2);
			font-size: 14px;
			font-weight: 500;
			pointer-events: all;
		}
	}

	#giveAHandCTA {
		position: fixed;
		bottom: 40px;
		right: 40px;
		font-size: 10px;
		letter-spacing: 1px;
		pointer-events: all;
		cursor: pointer;
		transform: translateX(200px);

		.giveAHandCircle {
			width: 96px;
			height: 96px;
			background-color: #d65e3d;
			border-radius: 50%;

			svg {
				margin-left: 30px;
				margin-top: 32px;
			}
		}

		.giveAHandText {
			margin-top: 10px;
			font-size: 14px;
			text-align: center;
		}
	}
}

.video-background {
	//	background: #000;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	//	z-index: -99;
	pointer-events: none;
}

.video-foreground,
.video-background video {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
}

#subtitlesOuter {
	position: fixed;
	bottom: 30px;
	left: 0px;
	width: 100vw;
	pointer-events: none;
	font-size: 18px;
	pointer-events: all;
	cursor: pointer;

	#subtitles {
		padding: 10px 20px;
		//	border-radius: 15px;
		//	background-color: #111111;
		filter: drop-shadow(2px 2px 2px rgba(0.5, 0, 0, 0));

		position: absolute;
		bottom: 0px;
		left: 50%;
		transform: translate(-50%, 0%);
		text-align: center;
	}
}

#IntroVideo {
	pointer-events: all;
}

@media (min-aspect-ratio: 1920/868) {
	.video-foreground {
		height: 300%;
		top: -100%;
	}
}

@media (max-aspect-ratio: 1920/868) {
	.video-foreground {
		width: 300%;
		left: -100%;
	}
}

#ClickToStart {
	opacity: 0;
	//background-color: ;
	font-family: $FONT_CTA;

	border-radius: 40px;
	padding: 12px 12px;
	width: 190px;
	margin: auto;
	font-size: 20px;
	font-weight: bold;
	cursor: default;
	pointer-events: all;
	text-align: center;
	color: #ffffff;
	border: 1px solid #ffffff;
	position: fixed;
	bottom: 77px;
	left: calc(50% - 95px);
}

#Preloader {
	position: fixed;
	font-family: 'poppins';
	height: 100vh;
	width: 100vw;
	pointer-events: none;

	.center {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		//margin-left: 19.3px;
		margin-top: 15px;

		@media (max-width: 700px) {
			margin-left: 12.6px;
		}

		h1 {
			font-weight: normal;
			font-family: 'poppins';
			letter-spacing: 14px;
			white-space: nowrap;
			margin-bottom: 10px;

			@media only screen and (max-width: 700px) {
				font-size: 20px;
			}
		}
	}

	.loader {
		position: fixed;
		bottom: 60px;
		width: 410px;
		left: calc(50% - 205px);

		.loaderText {
			text-align: center;
			font-family: $FONT;
			font-size: 12px;
			font-weight: lighter;
			margin-top: 15px;
		}

		.loaderLine {
			transform: scaleX(0);
			width: 100%;
			height: 1px;
			background-color: white;
		}

		.loaderAnim {
			position: absolute;
			//margin-top: 20px;
			margin: auto;

			img {
				//	transform: scale(0.4);
			}
		}
	}
}

.scrollToContinue {
	pointer-events: none;
	opacity: 0;
	position: fixed;
	bottom: 40px;
	right: 40px;
	font-size: 20px;
	font-weight: bold;
	color: #ffffff;
	font-family: $FONT_CTA;
	filter: drop-shadow(2px 2px 2px rgba(0.5, 0, 0, 0));
	transform: translateX(200px);

	.scrollToContinueText {
		font-family: $FONT_CTA;
	}
}

#IntroElements {
	position: fixed;
	font-family: $FONT_TITLE;
	font-style: normal;
	font-weight: 400;
	font-size: 32px;
	pointer-events: none;
	text-align: center;

	.title {
		opacity: 0;
		position: absolute;
		font-family: $FONT_TITLE;
		font-style: normal;
		font-weight: 400;
		font-size: 52px;
		//	font-weight: lighter;
		width: 100vw;
		top: calc(50vh - 40px);
		pointer-events: none;
		padding: 0px 20px 0px 20px;

		@media only screen and (max-width: 1000px) {
			font-size: 20px;
		}
	}

	.title1 {
		//top: 100vh;
	}

	.title2 {
		//	top: 200vh;
	}

	.title3 {
		//top: 300vh;
	}
}

* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	box-sizing: border-box;
	font-family: $FONT;
}

a,
a:visited,
a:active,
a:hover {
	text-decoration: underline;
	color: inherit;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
}

#App {
	.logo {
		position: absolute;
		top: 40px;
		left: 40px;
	}
}

#ViewContainer {
	display: none;
	top: 0;
	left: 0;
	position: absolute;
	width: 100%;
	height: 100%;
	pointer-events: none;
}

#TemplateLayer {
	display: none;
	top: 0;
	left: 0;
	position: absolute;
	width: 100%;
	min-height: 100vh;
}
