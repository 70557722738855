@import 'eases';

$FONT: 'REPLACE_ME', sans-serif;

$COLOR_PURPLE: #94039a;
$COLOR_YELLOW: #f6d700;
$COLOR_BLUE: #4c9dff;
$COLOR_GREEN: #00f097;
$COLOR_ORANGE: #fe7b00;
$COLOR_PINK: #ff80ce;

$COLOR_WHITE: #ffffff;
$COLOR_BLACK: #000000;

$COLOR_RED: #b64d30;
$COLOR_ARMY_GREEN: #666a60;
