.CaptureView {
	position: fixed;
	z-index: 30;
	pointer-events: auto;

	canvas {
		position: absolute;
		z-index: 1;
	}

	video {
		position: relative;
		width: 640px;
		height: auto;
	}

	img {
		user-select: none;
		pointer-events: none;
		opacity: 0;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 2;
	}

	.loader {
		position: absolute;
		top: calc(50vh - 40px);
		left: calc(50vw - 40px);
		z-index: 10;
		transform: scale(0.5, 0.5);
		opacity: 0;

		.imageContainer {
			position: relative;
			overflow: hidden;
			width: 0;
			height: 0;

			img {
				opacity: 1;
				position: absolute;
				right: unset;
				top: 0;
				left: 0;
			}
		}
	}

	.closeButton {
		position: absolute;
		right: 40px;
		top: 40px;
		z-index: 2;
		height: 44px;
		cursor: pointer;

		svg {
			margin-right: 9px;
		}

		.label {
			vertical-align: middle;
			color: white;
			font-family: $FONT;
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 20px;
			display: inline-block;
			height: 50px;
			user-select: none;
		}

		@media (max-width: 768px) {
			.label {
				display: none;
			}
		}
	}

	.snapButton {
		position: fixed;
		bottom: 60px;

		left: calc(50% - 48px);
		z-index: 10;
		cursor: pointer;

		svg {
			transform: scaleX(0.8) scaleY(0.8);

			circle {
				transition: opacity 0.2s;
			}

			&:hover {
				circle {
					opacity: 0.5;
					transition: opacity 0.2s;
				}
			}
		}
	}

	.tooltipRenderer {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		pointer-events: none;
		user-select: none;

		.tooltip {
			color: white;
			font-size: 10px;
			opacity: 0;
			position: absolute;
			left: 50px;
			top: 0;
		}
	}

	.title {
		font-family: $FONT_TITLE;
		color: #ffffff;
		user-select: none;
		pointer-events: none;

		//font-style: italic;
		font-weight: 500;
		font-size: 40px;
		line-height: 30px;

		text-align: center;

		position: absolute;
		z-index: 3;
		left: calc(50% - 250px);
		width: 500px;
		top: 102px;

		.sub {
			font-size: 12px;
		}
	}

	.gestureID {
		font-family: $FONT;
		font-style: normal;
		font-weight: 400;
		font-size: 10px;
		line-height: 16px;
		text-align: center;
		letter-spacing: 0.1em;
		text-transform: uppercase;
		color: #ffffff;

		position: absolute;
		z-index: 3;
		left: calc(50% - 100px);
		width: 200px;
		top: 168px;
	}

	@media (max-width: 768px) {
		.title {
			font-size: 24px;
		}
	}

	.titleStep {
		z-index: 3;
		position: fixed;
		top: 80px;
		left: calc(50% - 100px);
		font-style: normal;
		font-weight: 400;
		font-size: 10px;
		line-height: 16px;
		/* identical to box height, or 160% */

		width: 200px;
		user-select: none;
		pointer-events: none;
		text-align: center;
		letter-spacing: 0.1em;
		text-transform: uppercase;
	}

	.buttons {
		position: fixed;
		bottom: 100px;
		//left: calc(50% - 50vw);//230px);
		height: 44px;
		z-index: 2;
		display: flex;
		width: 100%;
		//max-width: 460px;
		justify-content: center;
		align-items: center;

		.back {
			//width: 220px;
			height: 48px;
			border: 2px solid rgba(255, 255, 255, 0.4);
			border-radius: 64px;
			//display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			margin-right: 20px;
			transition: 0.2s border;
			user-select: none;

			.label {
				color: white;
				display: inline-block;
				font-style: normal;
				font-weight: 700;
				font-size: 12px;
				line-height: 15px;
				text-align: center;
				letter-spacing: 0.02em;
				text-transform: uppercase;
				user-select: none;
			}

			&:hover {
				transition: 0.2s border;
				border: 2px solid white;
			}

			&.active {
				display: flex;
			}
		}

		.restart {
			@extend .back;
		}

		.confirm {
			@extend .back;
			opacity: 1;

			background: #ffffff;
			//border: none;
			border-radius: 64px;
			margin-right: 0;
			transition: 0.2s, background-color;

			.label {
				color: black;
				transition: 0.2s color;
			}

			&:hover {
				background-color: black;
				//border: none;
				transition: 0.2s border, background-color;

				.label {
					transition: 0.2s color;
					color: white;
				}
			}
		}

		.submit {
			@extend .confirm;
		}

		.gallery {
			@extend .confirm;
		}
	}

	.button {
		width: 50%;
		max-width: 220px;
		display: none;

		@media (max-width: 490px) {
			max-width: 160px;
		}

		&.active {
			display: block;
		}
	}

	.snapLabel {
		font-size: 14px;
		font-weight: 500;
		display: inline-block;
		position: absolute;
		top: 14px;
		left: 56px;
		z-index: 2;
		user-select: none;
		pointer-events: none;
		white-space: nowrap;
	}

	.buttonConfirm {
		left: 55px;
	}

	.divider {
		height: 1px;
		background-color: white;
		opacity: 0.5;
		user-select: none;
		pointer-events: none;
		width: 84.72222222vw;
		padding-right: 60px;

		position: absolute;
		z-index: 2;
		left: 30px;
		bottom: 80px;
	}

	.steps {
		position: fixed;
		z-index: 2;
		left: calc(50% - 180px);
		bottom: 40px;
		user-select: none;
		pointer-events: none;
		display: flex;

		@media (max-width: 700px) {
			left: calc(50% - 150px);
		}

		* {
			user-select: none;
		}

		.step {
			width: 120px;
			opacity: 0.4;
			transition: 0.3s opacity;
			color: $COLOR_WHITE;
			font-size: 14px;
			font-weight: 500;
			position: relative;
			display: inline-block;
			//margin-right: 20px;
			text-align: center;
			user-select: none;
			pointer-events: none;
			//transform: translate3d(0px, 40px, 0px);

			border-bottom: 1px solid white;

			@media (max-width: 700px) {
				width: 100px;
			}

			&.active {
				opacity: 1;
				transition: 0.3s opacity;

				//&:after {
				//	content: '';
				//	position: absolute;
				//	width: 100%;
				//	height: 1px;
				//	background-color: $COLOR_WHITE;
				//	top: -17px;
				//	left: 0;
				//	user-select: none;
				//}
			}

			&:last-of-type {
				margin-right: 0;
			}
		}
	}

	.notifications {
		position: fixed;
		z-index: 2;
		bottom: 160px;
		left: calc(85% - 200px); // calc(85% - 200px);//84.72222222%;//15.27777778%;
		font-family: $FONT;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 20px;
		max-width: 280px;

		@media (max-width: 700px) {
			left: calc(50% - 130px);
			bottom: calc(50% - 100px);
			background-color: rgba(0, 0, 0, 0.5);
			border-radius: 14px;
			padding: 10px;
		}

		.notificationLabel {
			font-weight: 500;
			display: inline-block;
			user-select: none;
		}

		.notificationBody {
			margin-top: 34px;
			user-select: none;
		}

		.notification {
			position: relative;
			display: none;
			opacity: 0;

			&.active {
				display: inline-block;
			}
		}

		.consent {
			.inner {
				cursor: pointer;
			}

			.imageContainer {
				margin-bottom: 20px;

				canvas {
					position: relative;
				}
			}

			svg {
				cursor: pointer;
			}

			.checkmark {
				opacity: 0;
			}

			.notificationBody {
				margin-bottom: 20px;
			}

			.notificationLabel {
				position: absolute;
				bottom: 3px;
				left: 28px;
			}
		}

		.error {
			.icon {
				display: inline-block;
				transform: translate3d(0px, 7px, 0px);
				margin-right: 11px;

				svg {
					width: 24px;
					height: 24px;
				}
			}
		}
	}

	.referenceSnapshot {
		position: fixed;
		top: 0;
		left: 0;
		pointer-events: none;
		user-select: none;
		z-index: 2;
	}

	.spinner {
		position: fixed;
		z-index: 99;
		user-select: none;
		pointer-events: none;
		top: calc(50% - 25px);
		left: calc(50% - 25px);
		width: 50px;
		height: 50px;
		animation: rotation 2s infinite linear;
		opacity: 0;

		svg {
			width: 100%;
			height: 100%;
		}
	}

	@keyframes rotation {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(359deg);
		}
	}
}
